// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Cfdatamigration7 from "../../blocks/cfdatamigration7/src/Cfdatamigration7";
import Cfcreateopenapitointegratewithcentralsystem from "../../blocks/cfcreateopenapitointegratewithcentralsystem/src/Cfcreateopenapitointegratewithcentralsystem";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Cfordermanagementworkflow2 from "../../blocks/cfordermanagementworkflow2/src/Cfordermanagementworkflow2";
import CreateComment from "../../blocks/comments/src/CreateComment";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Sorting from "../../blocks/sorting/src/Sorting";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cfaccessmanagement2 from "../../blocks/cfaccessmanagement2/src/Cfaccessmanagement2";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailAccountRegistration.web'


const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Cfdatamigration7:{
 component:Cfdatamigration7,
path:"/Cfdatamigration7"},
Cfcreateopenapitointegratewithcentralsystem:{
 component:Cfcreateopenapitointegratewithcentralsystem,
path:"/Cfcreateopenapitointegratewithcentralsystem"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Cfordermanagementworkflow2:{
 component:Cfordermanagementworkflow2,
path:"/Cfordermanagementworkflow2"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Cfaccessmanagement2:{
 component:Cfaccessmanagement2,
path:"/Cfaccessmanagement2"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
EmailAccountRegistrationWeb:{
  component:EmailAccountRegistrationWeb,
  path:"/",
  exact:true
},

  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true,
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;