import React from "react";

// Customizable Area Start

import { Box, Typography, Grid, Button ,CircularProgress,styled} from '@mui/material';

import { logo, signupimg,microsoftlogo} from "./assets";


const StyledButton = styled(Button) ({
    borderRadius: '8px',
    padding: '10px 16px',
    width: '20rem',
    border: ' 1px solid #94A3B8',
    color: '#475569',
    textTransform: 'none',
    display: 'flex',
    fontWeight:700,
    fontSize:"16px",
    justifyContent:"center",
    
    alignItems: 'center',
    '&:hover': {
        borderColor: '#bbb',
    },
});

const CenteredBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
});
const SignupText = styled(Typography) ({
    fontWeight:700,
    fontSize:"24px",
    fontFamily:"DIN Round Pro",
    color:"#1E293B",
    marginBottom:"10px",
    lineHeight:"32px"
});
const SignupSubText = styled(Typography)({
    fontWeight:400,
    fontSize:"16px",
    fontFamily:"DIN Round Pro",
    color:"#1E293B",
    marginBottom:"20px",
    lineHeight:"24px"
});
const CrmHeading = styled(Typography)({
    fontWeight:500,
    fontSize:"24px",
    fontFamily:"DIN Round Pro",
    color:"#0F172A",
    lineHeight:"32px"
});
const CrmSubText = styled(Typography) ({
    fontWeight:500,
    fontSize:"14px",
    fontFamily:"DIN Round Pro",
    color:"#334155",
    lineHeight:"22px"
});
const TextLog = styled(Typography) ({
    fontWeight:500,
    fontSize:"24px",
    fontFamily:"DIN Round Pro",
    color:"#1E293B",
    lineHeight:"32px",
    textAlign:"center",
    marginBottom:"15px"
});
// Customizable Area End

import EmailAccountRegistrationController, {
    Props,
} from "./EmailAccountRegistrationController";



export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            // Required for all blocks
            <Box>
            {!this.state.login ? (
                <Grid container sx={{ height: '100vh' }}>
                    <Grid item xl={4} lg={4} sm={6} md={6} sx={{ display: 'flex', flexDirection: 'column', padding: 2,backgroundColor:"#ffffff" }}>
                        <Box sx={{ alignSelf: 'flex-start', mb: 2 }}>
                            <img
                                src={logo.default}
                                alt="AsiaPac Logo"
                                style={{ width: '166px', height: 'auto' }}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                flexGrow: 1,
                            }}
                        >
                            <SignupText  variant="h6" sx={{ fontWeight: 700, mb: 1 }}>
                                Sign in to AsiaPac
                            </SignupText>

                            <SignupSubText
                                
                                sx={{
                                    textAlign: 'center',
                                    mb: 1,
                                }}
                            >
                                Enter Your Microsoft Account To Continue
                            </SignupSubText>

                            <SignupSubText
                            
                                sx={{
                                    textAlign: 'center',
                                    mb: 3,
                                }}
                            >
                                Managing Your CRM Tasks.
                            </SignupSubText>

                            <StyledButton
                            data-test-id="microBtn"
                                variant="outlined"
                                onClick={() => this.loggedIn()}
                                startIcon={
                                    <img
                                        alt="Microsoft Logo"
                                        src={microsoftlogo.default}
                                        style={{ width: 24, height: 24 }}
                                    />
                                }
                            >
                                Log in with Microsoft
                            </StyledButton>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xl={8}
                        lg={8}
                        sm={6}
                        md={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#F1F5F9',
                        }}
                    >
                        <img
                            src={signupimg.default}
                            alt="Dummy Illustration"
                            style={{ maxWidth: '80%', maxHeight: '80%', objectFit: 'cover' }}
                        />
                        <Box sx={{ textAlign: 'center', marginTop: 6 }}>
                            <CrmHeading variant="h6" sx={{ mb: 1 }}>
                            "Access Your CRM Workspace"
                            </CrmHeading>

                            <CrmSubText
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                    mb: 1,
                                }}
                            >
                                "Stay organized and efficient. Your customer management hub is just a login away."
                            </CrmSubText>
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <CenteredBox>
                    <img
                    data-test-id="logo"
                       src={logo.default}
                        alt="AsiaPac Logo"
                        style={{ width: '150px', height: 'auto', marginBottom: '20px' }}
                    />

                    <TextLog>
                        Verifying your login details, this may <br />
                        take a few seconds...
                    </TextLog>

                    <CircularProgress color="secondary" />
                </CenteredBox>
            )}
        </Box>
        
            // Customizable Area End
        );
    }

}